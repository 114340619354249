import { _ } from "vue-underscore";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";

export default {
  data: () => ({
    content: "",
    backUrl: "/audience",
    selectedAgents: [],
    audience: {
      id: 0,
      name: "",

      description: "",
      agent_audience: false,
      filters: {
        agent_ids: [],
        old_sold_client: false,
        old_unsold_client: false,
        new_client: false,
        sold_active_client: false,
        sold_inactive_client: false,
        unsubscriber: false,
        only_subscriber: false,
        os_af: false,
        os_as: false,
        os_eu: false,
        os_isc: false,
        os_me: false,
        os_na: false,
        os_oc: false,
        os_sa: false,
        os_rp: false,
        os_fs: false,
        client_subscriber: false,
        cs_af: false,
        cs_as: false,
        cs_eu: false,
        cs_isc: false,
        cs_me: false,
        cs_na: false,
        cs_oc: false,
        cs_sa: false,
        cs_rp: false,
        cs_fs: false,
        lead_created_after_date: "",
        lead_sold_after_date: "",
      },
    },
    audienceSelected: "Simple Audience",
    showNestedCheckboxes: false,
    datePickerFormat: "YYYY-MM-DD",
    showNestedCheckboxesClientSubscriber: false,
    audienceList: ["Simple Audience", "Agent Specific Audience"],
    AgentList: [],
    nestedCheckboxes: {
      os_af: "Africa",
      os_as: "Asia",
      os_eu: "Europe",
      os_isc: "Indian Sub-Continent",
      os_me: "Middle East",
      os_na: "North America",
      os_oc: "Oceania Countries",
      os_sa: "South America",
      os_rp: "Referral Program",
      os_fs: "Feedback Surveys",
    },
    nestedCheckboxes1: {
      cs_af: "Africa",
      cs_as: "Asia",
      cs_eu: "Europe",
      cs_isc: "Indian Sub-Continent",
      cs_me: "Middle East",
      cs_na: "North America",
      cs_oc: "Oceania Countries",
      cs_sa: "South America",
      cs_rp: "Referral Program",
      cs_fs: "Feedback Surveys",
    },
  }),
  computed: {
    iconClass() {
      return this.showNestedCheckboxes
        ? "fa-solid fa-chevron-down"
        : "fa-solid fa-chevron-right";
    },
    iconClass1() {
      return this.showNestedCheckboxesClientSubscriber
        ? "fa-solid fa-chevron-down"
        : "fa-solid fa-chevron-right";
    },
  },
  components: {
    "date-picker": DatePicker,
    Multiselect,
  },
  watch: {
    audienceSelected(newValue) {
      this.audience.agent_audience = newValue === "Agent Specific Audience";
    },
    "audience.filters.only_subscriber"(newVal) {
      if (!newVal) {
        // If only_subscriber is unchecked, uncheck all nested checkboxes
        Object.keys(this.audience.filters).forEach((key) => {
          if (key !== "only_subscriber") {
            this.$set(this.audience.filters, key, false);
          }
        });
      }
    },
    "audience.filters.client_subscriber"(newVal) {
      if (!newVal) {
        // If client_subscriber is unchecked, uncheck all nested checkboxes
        Object.keys(this.audience.filters).forEach((key) => {
          if (key !== "client_subscriber") {
            this.$set(this.audience.filters, key, false);
          }
        });
      }
    },
  },
  methods: {
    clearFilter() {
      let _vm = this;
      _vm.selectedAgents = [];
      _vm.audience.filters.agent_ids = [];
      _vm.audience.filters.old_sold_client = false;
      _vm.audience.filters.old_unsold_client = false;
      _vm.audience.filters.new_client = false;
      _vm.audience.filters.sold_active_client = false;
      _vm.audience.filters.sold_inactive_client = false;
      _vm.audience.filters.unsubscriber = false;
      _vm.audience.filters.only_subscriber = false;
      _vm.audience.filters.os_af = false;
      _vm.audience.filters.os_as = false;
      _vm.audience.filters.os_eu = false;
      _vm.audience.filters.os_isc = false;
      _vm.audience.filters.os_me = false;
      _vm.audience.filters.os_na = false;
      _vm.audience.filters.os_oc = false;
      _vm.audience.filters.os_sa = false;
      _vm.audience.filters.os_rp = false;
      _vm.audience.filters.os_fs = false;
      _vm.audience.filters.client_subscriber = false;
      _vm.audience.filters.cs_af = false;
      _vm.audience.filters.cs_as = false;
      _vm.audience.filters.cs_eu = false;
      _vm.audience.filters.cs_isc = false;
      _vm.audience.filters.cs_me = false;
      _vm.audience.filters.cs_na = false;
      _vm.audience.filters.cs_oc = false;
      _vm.audience.filters.cs_sa = false;
      _vm.audience.filters.cs_rp = false;
      _vm.audience.filters.cs_fs = false;
      _vm.audience.filters.lead_created_after_date = "";
      _vm.audience.filters.lead_sold_after_date = "";
    },
    selectAllNested() {
      const isChecked = this.audience.filters.only_subscriber;
      Object.keys(this.nestedCheckboxes).forEach((id) => {
        this.$set(this.audience.filters, id, isChecked);
      });
    },
    selectAllNested1() {
      const isChecked = this.audience.filters.client_subscriber;
      Object.keys(this.nestedCheckboxes1).forEach((id) => {
        this.$set(this.audience.filters, id, isChecked);
      });
    },
    updateSelectedAgents(newAgents) {
      this.audience.filters.agent_ids = newAgents
        ? newAgents.map((agent) => agent.id)
        : [];
    },
    validateAudience() {
      this.audience.id > 0 ? this.update() : this.add();
    },
    update() {
      let _vm = this;
      const option = [];

      this.axios
        .post("/audiences/" + this.audience.id, {
          ...this.audience,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.audience = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      _vm.axios
        .post("/audiences", _vm.audience)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    getDetail() {
      let _vm = this;
      const option = [];
      this.axios
        .get("/audiences/" + this.$route.params.id)
        .then(function (response) {
          _vm.audience = response.data.data;
          if (response.data.data.agent_audience) {
            _vm.audienceSelected = "Agent Specific Audience";
          } else {
            _vm.audienceSelected = "Simple Audience";
          }
          _vm.selectedAgents = _vm.AgentList.filter((agent) =>
            _vm.audience.filters.agent_ids.includes(agent.id)
          );
        })
        .catch(function () {});
    },
    getAgentDetail() {
      const _vm = this;
      this.axios
        .get("/team-wise-user")
        .then(function (response) {
          _vm.AgentList = response.data.data;
          _vm.selectedAgents = _vm.AgentList.filter((agent) =>
            _vm.audience.filters.agent_ids.includes(agent.id)
          );
        })
        .catch(function () {});
    },
    toggleNestedCheckboxes() {
      this.showNestedCheckboxes = !this.showNestedCheckboxes;
    },
    toggleNestedCheckboxesClientSubscriber() {
      this.showNestedCheckboxesClientSubscriber =
        !this.showNestedCheckboxesClientSubscriber;
    },
  },
  mounted() {
    this.getAgentDetail();
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
